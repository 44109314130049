






  import { Component, Prop, Vue } from 'nuxt-property-decorator'

  @Component
  export default class OneCmsGridLinkComponent extends Vue {
    @Prop({
      type: String,
      required: false,
    })
    readonly link!: string;

    @Prop({
      type: String,
      required: false,
    })
    readonly label!: string;

    @Prop({
      type: String,
      required: false,
    })
    readonly target?: string;

    get isLocalLink() {
      return this.link.startsWith('/')
    }
  }
